import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
import { ColorSwatch, ColorSwatchGrid } from '../../../components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`For specific SCSS color variables, use the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/tokens"
        }}>{`Token Library`}</a></strong>{`
.`}</p>
    <h2>{`Neutral Colors`}</h2>
    <p>{`The neutral palette is designed to maximize legibility, accessibility and usability. While the full palette is shown, we limited use within the UI to the seven levels. Each neutral has a primary function within the UI, but can be leveraged for other uses.`}</p>
    <h4>{`Accessibility`}</h4>
    <p>{`Both text colors – medium and dark – pass AA (+4.5) on all three background values.`}</p>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch name="White" value="color-white" notes={'Foreground: Component background, the focus of the experience'} mdxType="ColorSwatch" />
      <ColorSwatch name="50" value="color-gray-50" notes={'Highlights'} mdxType="ColorSwatch" />
      <ColorSwatch name="100" value="color-gray-100" notes={'Ground: Full-screen background'} mdxType="ColorSwatch" />
      <ColorSwatch name="200" value="color-gray-200" notes={'Inactive: Hidden tabs, collapsed components'} mdxType="ColorSwatch" />
      <ColorSwatch name="300" value="color-gray-300" notes={'Extra light borders: Component borders'} mdxType="ColorSwatch" />
      <ColorSwatch name="400" value="color-gray-400" notes={'Light borders: Element borders (e.g. tabs, buttons, filters), Text disabled'} mdxType="ColorSwatch" />
      <ColorSwatch name="500" value="color-gray-500" notes={'Not used'} mdxType="ColorSwatch" />
      <ColorSwatch name="600" value="color-gray-600" notes={'Not used'} mdxType="ColorSwatch" />
      <ColorSwatch name="700" value="color-gray-700" notes={'Text medium: Secondary content'} mdxType="ColorSwatch" />
      <ColorSwatch name="800" value="color-gray-800" notes={'Not used'} mdxType="ColorSwatch" />
      <ColorSwatch name="900" value="color-gray-900" notes={'Not used'} mdxType="ColorSwatch" />
      <ColorSwatch name="1000" value="color-gray-1000" notes='Text dark: Primary content' mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h2>{`Primary Colors`}</h2>
    <p>{`Primary colors are a brighter and more saturated set of blues, customized for buttons, links, and interactions. This palette is designed to pop, helping points of interaction and states stand out from the rest of the UI.`}</p>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch name="Brand" value="color-primary-brand" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Brand Medium" value="color-primary-medium" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Brand Dark" value="color-primary-dark" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Selection" value="color-primary-selection" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Selection A70" value="color-primary-selection-a70" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Selection A50" value="color-primary-selection-a50" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Focus" value="color-primary-focus" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Hover" value="color-primary-hover" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Hover Light" value="color-primary-hover-light" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch name="Hover A50" value="color-primary-hover-a50" notes={''} mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h2>{`Semantic Colors`}</h2>
    <p>{`Use semantic colors for status icons, badge backgrounds, and modal title bars. A separate value has been selected for text. Text colors pass AA (+4.5) on all background values. Reversed text on fill values passes AA (+4.5).`}</p>
    <h3>{`Info`}</h3>
    <p>{`Content about how base colors are used.`}</p>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="color-info-50" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="color-info-100" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="color-info-200" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="color-info-300" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="color-info-400" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="color-info-500" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="color-info-600" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="color-info-700" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="color-info-800" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="color-info-900" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="color-info-1000" notes={''} mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Success`}</h3>
    <p>{`Content about how base colors are used.`}</p>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="color-success-50" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="color-success-100" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="color-success-200" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="color-success-300" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="color-success-400" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="color-success-500" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="color-success-600" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="color-success-700" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="color-success-800" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="color-success-900" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="color-success-1000" notes={''} mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`In Progress`}</h3>
    <p>{`Content about how base colors are used.`}</p>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="color-in-progress-50" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="color-in-progress-100" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="color-in-progress-200" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="color-in-progress-300" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="color-in-progress-400" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="color-in-progress-500" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="color-in-progress-600" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="color-in-progress-700" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="color-in-progress-800" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="color-in-progress-900" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="color-in-progress-1000" notes={''} mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Warning`}</h3>
    <p>{`Content about how base colors are used.`}</p>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="color-warning-50" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="color-warning-100" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="color-warning-200" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="color-warning-300" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="color-warning-400" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="color-warning-500" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="color-warning-600" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="color-warning-700" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="color-warning-800" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="color-warning-900" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="color-warning-1000" notes={''} mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Error`}</h3>
    <p>{`Content about how base colors are used.`}</p>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="color-error-50" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="color-error-100" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="color-error-200" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="color-error-300" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="color-error-400" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="color-error-500" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="color-error-600" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="color-error-700" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="color-error-800" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="color-error-900" notes={''} mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="color-error-1000" notes={''} mdxType="ColorSwatch" />
    </ColorSwatchGrid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      